/**
 * OneScroll
 * @class OneScroll
 * @returns
 */
var OneScroll = Klass.create();

//definition start
OneScroll.extend(JqSprite);
OneScroll.extend({
/**
 * 初期処理
 */
init:function($o){
	// console.log('OneScroll Constract');
	// this.o = $o;
	this.w_scroll = 0;
	this.delta = 0;
	this.downFlag = true;
	this.upFlag = false;
	this.offsetY = 0; //resize
	// this.o = $o;// $('#pointer')
	// this.cWidth = 8; //カーソルの大きさ
	// this.fWidth = 40; //フォロワーの大きさ
	// this.mouseX = 0; //マウスカーソルX座標
	// this.mouseY = 0; //マウスカーソルY座標
	// this.targetClass = 'active';
	// this.set();
},
/**
 * 設定
 */
set:function(){
	// console.log('OneScroll set');
	this.setObject.apply(this,[]);
	// this.setParam.apply(this,[]);
	this.setState.apply(this,[]);
	this.setEvent.apply(this,[]);
},
/**
 * オブジェクトセット
 */
setObject:function(){
	// console.log('OneScroll setObject');
	this.$document = $(document);
	this.$statement = $('#statement');
},
/**
 * パラメータセット
 */
// setParam:function(){
// 	// console.log('OneScroll setParam');
// 	// init の メンバ変数に似ているが、何か計算したり取得し直す時に、値などここに入れる
// },
/**
 * 初期位置設定
 */
setState:function(){
	// console.log('OneScroll setState');
	this.resize();
},
/**
 * イベントセット
 */
setEvent:function(){
	// console.log('OneScroll setEvent');
	var oSelf = this;
	// console.log(oSelf);
	/*--------------------------
	  PC
	--------------------------*/
	if(!_cmn.ua['sp']){
		var mousewheelevent = 'onwheel' in	 document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
		// console.log('mousewheelevent');
		// console.log(mousewheelevent);

		this.$document.on(mousewheelevent,function(e){

			// console.log(oSelf.w_scroll);
			// if(!(setWrap.is(':animated'))){
			oSelf.w_scroll = $(window).scrollTop();

			// スクロール量が statement の上部よりすくなくなったら(KVを表示する位置まできたら)
			// if(oSelf.w_scroll <= oSelf.offsetY && upFlag){
			// 	console.log('KV 表示位置');
			// 	$("body,html").css({"overflowY":"hidden"});
			// 	$("html,body").animate({scrollTop:oSelf.offsetY},0);
			// }

			oSelf.delta = e.originalEvent.deltaY ? -(e.originalEvent.deltaY) : e.originalEvent.wheelDelta ? e.originalEvent.wheelDelta : -(e.originalEvent.detail);
			// e.preventDefault();
			if (oSelf.delta < 0 && oSelf.downFlag){
				// console.log('Do motionDown()');	
				oSelf.motionDown();
			}
			// console.log(oSelf);
			if (oSelf.delta > 0 && oSelf.upFlag && oSelf.w_scroll <= (oSelf.offsetY + 10)){
				// console.log('Do motionUp()');
				$("body,html").css({"overflowY":"hidden"}).scrollTop(oSelf.offsetY);
				oSelf.motionUp();
			}
		});
	}
	/*--------------------------
	  SP
	--------------------------*/
	if(_cmn.ua['sp']){
		var isTouch = ('ontouchstart' in window);
		// alert(isTouch);
		$('body').on(
			{'touchstart': function(e){
				// console.log(oSelf);
				if($('html, body').is(':animated')){
					// alert('$html, body return false');
					// e.preventDefault();
					return false;
				} else {
					// alert('touchstart not animated');
					// $("html,body").css({"overflowY":"hidden"});
					this.pageY = (isTouch ? event.changedTouches[0].pageY : e.pageY);// タッチした座標
					this.topBegin = parseInt($(this).css('top'));// 0 絶対
					this.top = parseInt($(this).css('top'));
					this.touched = true;
					// console.log(oSelf.w_scroll);
					// console.log(oSelf.offsetY);
				}
			},'touchmove': function(e){
				if(!this.touched){return;}
				// e.preventDefault();
				oSelf.w_scroll = $(window).scrollTop();
				// alert('ccc');
				// this.top = this.top - (this.pageY - (isTouch ? event.changedTouches[0].pageY : e.pageY));
				// this.pageY = (isTouch ? event.changedTouches[0].pageY : e.pageY);
				// console.log(this.topBegin);
				// console.log(this.top);
				// console.log('タッチした場所 - ' + this.pageY);
				// console.log('スクロール量 - ' + oSelf.w_scroll);
				// console.log(oSelf.offsetY);

				if(oSelf.upFlag && oSelf.w_scroll <= (oSelf.offsetY - 20)){
					// console.log('KV の中');
					// console.log('oSelf.w_scroll - ' + oSelf.w_scroll);
					// console.log('oSelf.offsetY - ' + oSelf.offsetY);
				$("body,html").scrollTop(oSelf.offsetY);
				}
			},'touchend': function(e){
				if (!this.touched) {return;}
				this.touched = false;
				// console.log(oSelf);
				// console.log(oSelf.offsetY);
				// console.log('oSelf.downFlag - ' + oSelf.downFlag);
				// console.log(oSelf.downFlag);
				// console.log('oSelf.upFlag - ' + oSelf.upFlag);
				// console.log(oSelf.upFlag);
				// if(((this.topBegin)-30) > this.top){
				if(oSelf.downFlag){
					// alert('ddd');
					// console.log(this.topBegin);
					oSelf.motionDown();
				// } else if(((this.topBegin)+30) < this.top && oSelf.upFlag){
				} else if(oSelf.upFlag && oSelf.w_scroll <= (oSelf.offsetY + 10)){
					// alert('eee');
					// console.log(this.topBegin);
					// 角着くので 最後にcss
					$("body,html").scrollTop(oSelf.offsetY);
					oSelf.motionUp();
				}
			}
		}, {passive: false});
	}
	/* click */
	/* resize */
	
	// $(window).on("resize",this.resize);
	$(window).on('resize', $.proxy(this.resize, this));
	// console.log(this.resize);
	
},
// 下に移動
motionDown:function(){
	// console.log('OneScroll motionDown');
	var oSelf = this;
	oSelf.downFlag = false;
	oSelf.upFlag = false;
	setTimeout(function(){
		$("html,body").animate({scrollTop:oSelf.offsetY}).css({"overflowY":"auto", 'height':'auto'});
		oSelf.upFlag = true;
	}, 200);
},
// 上に移動	
motionUp:function(){
	// console.log('OneScroll motionUp');
	var oSelf = this;
	oSelf.downFlag = false;
	oSelf.upFlag = false;
	// $("html,body").css({"overflowY":"hidden"}).animate({scrollTop:oSelf.offsetY},0);
	$("html,body").animate({scrollTop:0});
	setTimeout(function(){
		// $("html,body").animate({scrollTop:0}).css({"overflowY":"hidden", 'height':'100%'});
		$("html,body").css({"overflowY":"hidden", 'height':'100%'});
		oSelf.downFlag = true;
	}, 200);
},	
/**
 * 表示
 */
// xxx:function(i){
// 	//console.log(i);
// 	if(_manager.OneScroll.anim){ return; }
// 	var $target = _manager.OneScroll.area[i];
// 	var oSelf = this;
// 	var oAsync = this.getAsyncCommand(arguments);
// 	var oCom = new Command();
// 	oCom.call(this,function(){ _manager.OneScroll.anim = true; },[],"");
// 	oCom.call(_cmn,_cmn.passiveBody,[],"");
// 	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
// 	oCom.execute();
// },

/**
 * リサイズ
 */
resize:function(){
	// console.log('resize');
	// console.log(this.offsetY);
	this.offsetY = this.$statement.offset().top;
}
});
