/**
 * MouseStalker
 * @class MouseStalker
 * @returns
 */
var MouseStalker = Klass.create();

//definition start
MouseStalker.extend(JqSprite);
MouseStalker.extend({
/**
 * 初期処理
 */
init:function($o){
	// console.log('MouseStalker Constract');
	this.o = $o;// $('#pointer')
	this.cWidth = 8; //カーソルの大きさ
	this.fWidth = 40; //フォロワーの大きさ
	this.mouseX = 0; //マウスカーソルX座標
	this.mouseY = 0; //マウスカーソルY座標
	this.targetClass = 'active';
	// this.set();
},
/**
 * 設定
 */
set:function(){
	// console.log('MouseStalker set');
	this.setObject.apply(this,[]);
	// this.setParam.apply(this,[]);
	this.setState.apply(this,[]);
	this.setEvent.apply(this,[]);
},
/**
 * オブジェクトセット
 */
setObject:function(){
	// console.log('MouseStalker setObject');
	// this.$pointer = $('#pointer');
	this.$cursor = this.o.find("#cursor");
	this.$follower = this.o.find("#follower");
	this.$document = $(document);
	this.$body = $('body');
	this.$link = $('a');
},
/**
 * パラメータセット
 */
setParam:function(){
	// console.log('MouseStalker setParam');
	// init の メンバ変数に似ているが、何か計算したり取得し直す時に、値などここに入れる

	// 本当はCSSの値を取得して設定したいができない
	// this.cWidth = this.$cursor.width(); //カーソルの大きさ
	// this.fWidth = this.$follower.width(); //フォロワーの大きさ console.log(this.cWidth); console.log(this.fWidth);
},
/**
 * 初期位置設定
 */
setState:function(){
	// console.log('MouseStalker setState');
	this.$body.addClass('is_mouse_stalker');
	// 本当はCSSの値(横 縦)を取得して設定したいが、できない
	// サイズ変更の際、JSだけで修正できる様にここで値を設定
	this.$cursor.width(this.cWidth).height(this.cWidth);
	this.$follower.width(this.fWidth).height(this.fWidth);
},
/**
 * イベントセット
 */
setEvent:function(){
	// console.log('MouseStalker setEvent');
	var oSlef = this;
	/*
	Mouse Event
	*/
	// mouseMove
	this.$document.on('mousemove', function(e){
		// console.log('mousemove'); // console.log('---mouseX---' + this.mouseX); // console.log('---mouseX---' + oSlef.mouseX); // console.log('---mouseY---' + this.mouseY); // console.log('---mouseY---' + oSlef.mouseY);
		oSlef.mouseX = e.clientX;
		oSlef.mouseY = e.clientY;
		oSlef.calcMousePosition(oSlef.$cursor, oSlef.mouseX, oSlef.mouseY, oSlef.cWidth);
		oSlef.calcMousePosition(oSlef.$follower, oSlef.mouseX, oSlef.mouseY, oSlef.fWidth);
	});
	// mousEnter mouseLeave
	this.$link.on({
		'mouseenter': function() {
			oSlef.o.addClass(oSlef.targetClass);
		},
		'mouseleave': function() {
			oSlef.o.removeClass(oSlef.targetClass);
		}
	});
	/* click */
	/* resize */
	// $(window).on("resize",this.resize);
},
calcMousePosition: function($obj,x , y, w){
	// console.log($obj);
	$obj.css({// 中心に来る様に 横幅の半分を引く
		left: x - (w/2),
		top: y - (w/2)
	});
},
/**
 * closeボタン押下時処理
 */
// setCloseEvent:function(e){
// 	_manager.MouseStalker.close.on("click",_manager.MouseStalker.hide);
// },
/**
 * closeボタン押下時処理
 */
// delCloseEvent:function(e){
// 	_manager.MouseStalker.close.off("click");
// },

/**
 * 表示
 */
// xxx:function(i){
// 	//console.log(i);
// 	if(_manager.MouseStalker.anim){ return; }
// 	var $target = _manager.MouseStalker.area[i];
// 	var oSelf = this;
// 	var oAsync = this.getAsyncCommand(arguments);
// 	var oCom = new Command();
// 	oCom.call(this,function(){ _manager.MouseStalker.anim = true; },[],"");
// 	oCom.call(_cmn,_cmn.passiveBody,[],"");
// 	oCom.call(oSelf,oSelf.setCloseEvent,[],"");
// 	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
// 	oCom.execute();
// },

/**
 * リサイズ
 */
// resize:function(){
// }
});
