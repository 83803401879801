/**
 * Manager
 * @class Page Manager
 * @returns
 */
// var _data = [
// {title:'MAKE KEEP MIST',yid:'wirk_e0k344'}
// ];

var Manager = Klass.create();

Manager.extend(JqManager);

//definition start
Manager.extend({
/**
 * init
 */
init:function(){
	// this.timer = null;
	// this.perf_no = 1;
	// this.app_no = 1;
	this.anim = false;

	this.targetClass='active';
	this.targetClass2='read_more';
	this.flagCancel = true;
	// this.winWidth = 0;
	this.iWindowW = window.innerWidth;
	this.iWindowH = window.innerHeight;
	this.scroll = 0;

	this.secAry = ['kv', 'statement', 'project'];
	this.animTime = 300;
	
	this.fstFlag = true;

	// this.offsetY = $('#statement').offset().top;
	//if(_cmn.ua['sp']){
	//	$('body').addClass('sp');
	//}
},
/**
 * 設定
 */
set:function(){
	this.setParam();
	this.setObject();
},
/**
 * 開始
 */
start:function(){
	// console.log('start');
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this, this.setState, [],"");
	oCom.call(this, this.setEvent, [],"");
	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 * 開始後
 */
after:function(){
  // console.log('after');
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	// oCom.call(this, this.showLoading, [],""); ここだと遅い
	// oCom.call(this, this.showKV, [],"");
	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
///////////////////////////////////////////////////////////////////////////////////
//setting
///////////////////////////////////////////////////////////////////////////////////
/**
 * パラメータセット
 */
setParam:function(){
	if(_cmn["ua"] === "ie" && ~~_cmn["ver"] === 8){
		this.ie8 = true;
	}
	if(_cmn["ua"] !== "ie" || (_cmn["ua"] === "ie" && ~~_cmn["ver"] >= 10)){
		this.modern = true;
	}
},
/**
 * オブジェクト設定
 */
setObject:function(){
	// _cmn.setScroll();

	// ワンスクロール
	this.oneScroll = OneScroll.create();
	this.oneScroll.set();

	// マウスストーカー PCのみ実装
	if(!_cmn.ua['sp']){
		this.mouseStalker = MouseStalker.create($('#pointer'));
		// init で実行じゃだめなの？
		this.mouseStalker.set();
	}

},
/**
 * 初期状態セット
 */
setState:function(){
	if(!_manager.ie8){
		if(_manager.modern){
			//modern
		}else{
			//ie9
		}
	}else{
		//ie8
	}
	$('body').removeClass('hide');
	// アニメーションさせるためにスパンで囲む
	this.setElmForAnim($('.js_set_elm'));

	//if($('.js_chng_kv').hasClass('js_kv')) {}
	if(_cmn.ua['sp']){
		$('#kv .kv').fadeOut(100, function(){ $(this).attr('src', '/wp/static/bin/page/top/img/kv.gif').fadeIn(100); });
	}

	// resize
	this.resize(null,false);
	
},
/**
 * setEvent
 */
setEvent:function(){
	//cmn
	this.setLoadEvent();// afterだと遅いので
	// this.setFlickEvent();
	// this.setOneScrollOneView();
	this.setWayPoint();
	// this.setScroll();
	this.setResize();
	// this.setClick();
},
setLoadEvent: function(){
	// console.log('setLoadEvent');
	this.showLoading($('#loader'));
},

/**
 * スクロール位置ベースのイベント
 */
setWayPoint:function(){
	// console.log('setWayPoint');
	var oSelf = this;

	// $('.wp').waypoint(function(direction) {
	// 	if (direction === 'down') {
	// 		$(this).addClass(_manager.targetClass);
	// 	}
	// },{offset:'80%'});

	// PC時、実行
		//console.log('pc');
		/*	
		 * スクロール位置で、data値を取得し、画像変更
		 * */
		// var secAry = ['kv', 'statement', 'project01', 'project02'];
		// // var secAryLength = secAry.length;

		// for(var i = 0; i < secAry.length; i++) {
		// 	$('#' + secAry[i]).waypoint(function(direction){
		// 		oSelf.changeKV($(this), '.photo img', direction);
		// 	},{offset:_manager.WindowH/2});
		// }

		// PCの時にがぞうが変わる様に
		if(!_cmn.ua['sp']){
			$('.js_chng_kv').waypoint(function(direction){
				// console.log($(this));
				// oSelf.changeKV($(this), '.photo img', direction);
				oSelf.changeKV($(this), '', direction);
			// },{offset:_manager.WindowH});
			},{offset:' 60%'});
		}

		/*
		 * スクロール位置で、ナビを変更
		 * */
		// var secAry2 = ['kv', 'statement', 'project'];
		// var secAryLength2 = secAry2.length;
		oSelf.changeMenu(oSelf.secAry ,$('.nav .list li'), 'nav');

		// KV サイズ変更、クラス変更 ナビ
		var flag_state = true;
		$('#statement').waypoint(function(direction){
			// console.log('#statement KV サイズ変更、クラス変更 ナビ');
			// 後で修正
			oSelf.showKVSP();
			if (direction === 'down'){
				// console.log('aaa');
				// $('#kv .photo').removeClass('first');
				// $('#kv .photo').removeClass('first').addClass(_manager.targetClass);
				// $('#kv .photo').toggleClass(_manager.targetClass);
				if(flag_state == true) {
					console.log('ccc');
					flag_state = false;
					var delay = 12;
					$('.anim_type.n1 span').each(function(i){
						$(this).delay(i * delay).queue(function(next) {
							$(this).addClass(_manager.targetClass);
							next();
						});
					}).promise().done(function(){
						// console.log('end');
						setTimeout(function(){
							$('.anim_type.n2 span').each(function(i){
								$(this).delay(i * delay).queue(function(next) {
									$(this).addClass(_manager.targetClass);
									next();
								});
							})
						}, 300);
					});
				}
			} else if(direction === 'up'){
				// console.log('bbb');
			};
		},{offset:'60%'});
	// },{offset:_manager.WindowH - 100});

		// showProducts
		$('#project ul li').waypoint(function(direction){
			if (direction === 'down'){
				oSelf.showProducts($(this));
			} else if(direction === 'up'){
			}
		},{offset:'60%'});

},
/**
 * スクロールイベントセット
 *  - パララクス の様にスクロール量で変えるものとかに使うのだろうな
 */
// setScroll:function(){
// 	console.log('setScroll');
// 	$(window).on('load scroll', function() {
// 		_manager.scroll = $(this).scrollTop() * 0.1; //スクロールの値を取得
// 	});
// },
/**
 * リサイズイベントセット
 */
setResize:function(){
	// console.log('setResize');
	$(window).bind('resize',function(e){_manager.resize(e,true);});
},
/**
 * クリックイベントセット
 */
setClick:function(){
	// console.log('setClick');
},
///////////////////////////////////////////////////////////////////////////////////
//event
///////////////////////////////////////////////////////////////////////////////////
// クラスの表記揺れを無くせる
//$(this).toggleClass(_manager.targetClass); 同じ
switchClass: function($elm) {
	$elm.toggleClass(_manager.targetClass);
},
///////////////////////////////////////////////////////////////////////////////////
//function
///////////////////////////////////////////////////////////////////////////////////
showLoading:function($elm){
	// console.log('showLoading');
	var delay = 20;
	$elm.find('.cls').each(function(i){
		$(this).delay(i * delay).queue(function(next) {
			$(this).addClass(_manager.targetClass);
			next();
		});
	});
	// }).promise().done(function(){
	// });
},

changeKV:function($e, target, direction){// 60%で使用 
	// console.log('changeKV'); // console.log($e); //console.log(direction);
	var $elm = $e; // var num = 0;
	var pass;
	// var txtClass = $elm.attr('class');
	// var imgPass = '/wp/static/bin/page/top/img/icn'; // var extension  = '.svg';
	// console.log(txtClass);
	// console.log($elm.hasClass('js_kv'));

	if (direction === 'down'){ // console.log('changeKV___down'); // num = $elm.attr('data-down');
		pass = $elm.attr('data-down'); // if(txtClass == 'kv' || txtClass == 'statement') {

		// 初回 読み込み時 kvでgifあにめ再生
		if($elm.hasClass('js_kv') && _manager.fstFlag) { // console.log('return kv');
			$('#kv .kv').fadeOut(100, function(){ $(this).attr('src', '/wp/static/bin/page/top/img/kv.gif').fadeIn(100); });
			return false; 
		}

		// 初回 KVから移動の際 使用
		if($elm.hasClass('js_statement')) { // console.log('aaaaaaaaaaaa'); // console.log(_manager.fstFlag);
			_manager.showKV(pass);
			_manager.fstFlag = false;
			return false;
		}

	} else if(direction === 'up'){ // console.log('changeKV---up');
		pass = $elm.attr('data-up');
		
		if($elm.hasClass('js_statement')) { // console.log('aaaaaaaaaaaa'); // console.log(_manager.fstFlag);
		_manager.showKV(pass);
		_manager.fstFlag = false;
		return false;
	}
	} // pass = imgPass + num + extension;

	$('#kv .kv').fadeOut(200, function(){
		//_manager.switchClass($(this));
		$(this).attr('src', pass).fadeIn(200);
	});

},
//グローバルメニュー モジュール化しても良いかも
changeMenu:function(arry, $elm1, cls){
	// console.log('changeMenu');
	var oSelf = this;
	var count = -1; // console.log(arry); // console.log(arry.length);
	// jsじゃなくてhtmlに直書きしても良いかも しかも setStateの中に書くべき
	// $elm1.each(function(i) { // 	$(this).addClass('nav_'+secAry[i]); // });

	for(var i = 0; i < arry.length; i++) {
		// console.log(arry[i]);

		$('#' + arry[i]).waypoint(function(direction){
			if (direction === 'down'){
				count++;
				if($(this).attr('id') === 'statement') {
					$('#header .nav').toggleClass(oSelf.targetClass + ' ' + oSelf.targetClass2);
				}
			} else if(direction === 'up'){
				count--;
				if($(this).attr('id') === 'statement') {
					$('#header .nav').toggleClass(oSelf.targetClass).delay(3000).queue(function(next){
						$('#header .nav').toggleClass(oSelf.targetClass2);
						next();
					});
				}
			};
			//ナビの位置のため

			//表示、非表示のため
			$elm1.removeClass(oSelf.targetClass).delay(100).queue(function(next){
				$('.'+ cls + '_' + arry[count]).addClass(oSelf.targetClass);
				next();
			});
			
		},{offset:'60%'});
	}

},
showKV:function(pass){
	// console.log('showKV');
	if(!_cmn.ua['sp']){
		var oSelf = this;
		var oCom = new Command();
		oCom.call(this,function(){ $('#kv .kv, #kv .kv_bg').fadeOut(200) },[],"");
		oCom.sleep(200);
		if(_manager.fstFlag) {
			oCom.call(this,function(){ $('#kv .photo').removeClass('first'); },[],"");
			oCom.call(this,function(){ $('#kv .kv').attr('src', pass); },[],"");
		}
		oCom.call(this,function(){ $('#kv .photo').toggleClass(_manager.targetClass); },[],"");
		oCom.sleep(300);
		oCom.call(this,function(){ $('#kv .kv, #kv .kv_bg').fadeIn(300) },[],"");
		oCom.execute();
	}
},
showKVSP:function(pass){
	// console.log('showKVSP');
	if(_cmn.ua['sp']){
		var oSelf = this;
		var oCom = new Command();
		oCom.call(this,function(){ $('#kv .photo, #statement .icn').toggleClass('active') },[],"");
		// oCom.sleep(400);
		// oCom.call(this,function(){ $('#kv .kv, #statement .icn').fadeIn(300) },[],"");
		// oCom.call(this,function(){ $('#kv .kv, #kv .kv_bg').fadeOut(200) },[],"");
		// oCom.sleep(200);
		// if(_manager.fstFlag) {
		// 	oCom.call(this,function(){ $('#kv .photo').removeClass('first'); },[],"");
		// 	oCom.call(this,function(){ $('#kv .kv').attr('src', pass); },[],"");
		// }
		// oCom.call(this,function(){ $('#kv .photo').toggleClass(_manager.targetClass); },[],"");
		// oCom.sleep(300);
		// oCom.call(this,function(){ $('#kv .kv, #kv .kv_bg').fadeIn(300) },[],"");
		oCom.execute();
	}
},

// showStatement:function($elm){
// 	// console.log('showStatement');
// 	// console.log($elm);
// 	var oSelf = this;
// 	var oCom = new Command();
// 	// oCom.call(this,function(){ $('#header .nav').toggleClass(_manager.targetClass) },[],"");
// 	// oCom.call(this,function(){ oSelf.$kvPhoto.toggleClass(_manager.targetClass); },[],"");
// 	oCom.call(this,function(){ $('#kv .photo').toggleClass(_manager.targetClass); },[],"");

// 	//2回目以降、不要なのに呼ばれておかしい
// 	// oCom.call(this,function(){ $elm.find('.wp1').addClass(oSelf.targetClass) },[],"");
// 	// oCom.sleep(oSelf.animTime/2);
// 	// oCom.call(this,function(){ $elm.find('.wp2').addClass(oSelf.targetClass) },[],"");
// 	oCom.execute();
// },

showProducts:function($elm){
	// console.log('showProducts');
	// console.log($elm);
	var oSelf = this;
	var oCom = new Command();
	oCom.call(this,function(){ $elm.find('.wp1').addClass(oSelf.targetClass) },[],"");
	oCom.sleep(oSelf.animTime/2);
	oCom.call(this,function(){ $elm.find('.wp2').addClass(oSelf.targetClass) },[],"");
	oCom.sleep(oSelf.animTime/2);
	oCom.call(this,function(){ $elm.find('.wp3').addClass(oSelf.targetClass) },[],"");
	oCom.sleep(oSelf.animTime/2);
	oCom.call(this,function(){ $elm.find('.wp4').addClass(oSelf.targetClass) },[],"");
	oCom.execute();
},
///////////////////////////////////////////////////////////////////////////////////
//util
///////////////////////////////////////////////////////////////////////////////////
// each add Class
// setEachAddClass: function($elm){
// 	console.log('setEachAddClass');
// 	console.log($elm);
// 	console.log($elm[0]);
// 	console.log($elm[1]);

	// var delay = 20;
	// $elm.each(function(i){
	// 	$(this).delay(i * delay).queue(function(next) {
	// 		console.log(i);
	// 		// $this.delay(i * delay).queue(function(next) {
	// 		$(this).addClass(_manager.targetClass);
	// 		next();
	// 	});
	// });
// },
/**
 * set dom for animation 文字列を一文字ごとにspanで囲む
 */
setElmForAnim: function($elm, cls, elm) {
	// console.log('setElmForAnim');
	// 指定が無ければ span タグで囲む
	if(cls == undefined){ cls = 'cls'; }
	// 指定が無ければ span タグで囲む
	if(elm == undefined) { elm = 'span'; } // console.log($elm); // console.log($elm.children());//直下の子要素を全て取得 find - 直下以下全ての子要素を取得 // console.log($elm.children().andSelf());//現在と一つ前の要素 2つ取得 // console.log($elm.children().andSelf().contents());//要素のテキストノードも含めた全子要素を取得
	$elm.children().andSelf().contents().each(function() {
		// console.log(this); // console.log(this.nodeType); // console.log($(this));
		if (this.nodeType == 3) { // console.log($(this));
			$(this).replaceWith($(this).text().replace(/(\S)/g, '<' + elm + ' class="'+ cls + '">$1</' + elm + '>'));
    }
	});
},
/**
 * resize
 */
resize:function(e,b){
	//console.log('resize');
	var $window = $(window);
	var iWindowW = window.innerWidth;
	//var iWindowH = window.innerHeight;
	//これちゃんと効いてる？
	_manager.WindowW = window.innerWidth;
	_manager.WindowH = window.innerHeight;

}
});
//definition end
